import * as React from "react";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { IdentifierType } from "@shared-ui/retail-product-relevant-content";

export interface ProductBexApiComponentProps {
  inputs: {
    productIdentifier: {
      id: string;
    };
  };
}

interface ProductBexApiWrapperProps {
  context: ExtendedContextStore;
  additionalInputs?: object;
  onClickReviews?: () => void;
}

/**
 * Used to wrap Shared UI or Bex API components relevant to Properties (components in the @shared-ui/product-* packages).
 * @param Component
 * @param options
 * @constructor
 */
export const productIdentifierInputs = (context: ExtendedContextStore) => {
  return {
    id: String(context.searchContext.location.id),
    type: IdentifierType.PROPERTY_ID,
    travelSearchCriteria: {
      property: {
        primary: {
          dateRange: null,
          rooms: [{ adults: 2 }],
          destination: {
            regionId: String(context?.searchContext?.location?.parent?.id),
          },
        },
        secondary: {},
      },
    },
  };
};

export const ProductBexApiWrapper = <P extends { inputs: any }>(
  WrappedComponent: React.FC<P & ProductBexApiComponentProps>
) => {
  // @ts-ignore
  const Component: React.FC<Omit<P, "inputs"> & ProductBexApiWrapperProps> = ({
    context,
    additionalInputs,
    ...props
  }) => {
    if (context?.searchContext?.location?.type !== "hotel") {
      return null;
    }

    const inputs = { productIdentifier: productIdentifierInputs(context), ...additionalInputs };

    // @ts-ignore
    return <WrappedComponent inputs={inputs} {...props} />;
  };

  return Component;
};
