import * as React from "react";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

export interface PropertyBexApiComponentProps {
  inputs: {
    propertyId: string;
  };
}

interface PropertyBexApiWrapperProps {
  context: ExtendedContextStore;
  additionalInputs?: object;
  useExternalLink?: boolean;
  onClose?: () => void;
  triggerRef?: React.RefObject<HTMLElement>;
}

/**
 * Used to wrap Shared UI or Bex API components relevant to Properties (components in the @shared-ui/lodging-* packages).
 * If you use the additionalInfo props, make sure that all the components using the same query are in sync and have the
 * same variables, else duplicate queries will be sent to the downstreams services.
 *
 * See PropertyReviewsWrapper or PropertyReviewsHeadline for example.
 * @param Component
 * @param options
 * @constructor
 */
export const PropertyBexApiWrapper = <P extends { inputs: any }>(
  WrappedComponent: React.FC<P & PropertyBexApiComponentProps>
) => {
  // @ts-ignore
  const Component: React.FC<Omit<P, "inputs"> & PropertyBexApiWrapperProps> = ({
    context,
    additionalInputs,
    ...props
  }) => {
    if (context?.searchContext?.location?.type !== "hotel") {
      return null;
    }

    const inputs = {
      propertyId: String(context.searchContext.location.id),
      searchCriteria: {
        primary: {
          dateRange: null,
          rooms: [{ adults: 2 }],
          destination: {
            regionId: String(context?.searchContext?.location?.parent?.id),
          },
        },
      },
      ...additionalInputs,
    };

    // @ts-ignore
    return <WrappedComponent inputs={inputs} {...props} />;
  };

  return Component;
};
